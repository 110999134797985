import { Header, Timeline } from './Headers';

export function Education() {
  return <div style={{ paddingLeft: '2.5%', paddingRight: '2.5%' }}>
    <Header>
      Education
    </Header>

    <h4 style={{ margin: 0 }}>University of Tennessee at Chattanooga
      <br />
      <Timeline>
        08/2008 - 12/2013
      </Timeline>
    </h4>
    <p >Graduated with a Batchelors in Computer Science with focus on information security</p>

  </div>
}