import { Header } from './Headers';



export function ProfessionalSummary() {
  return (
    <div style={{ paddingLeft: '2.5%', paddingRight: '2.5%', paddingTop: '2.5%' }}>
      <Header >
        Professional Summary
      </Header>
      <p style={{ marginTop: 0 }}>
        As a Senior Software Developer, I bring over 11 years of dynamic experience in spearheading cross-platform projects collaborating with members, and crafting innovative solutions. Proficient in Java (Android), React Native, and Bluetooth technologies, I excel in optimizing customer experiences and designing robust frameworks. My achievements include leading the development of customer-facing applications, flows, and creating multiple Standard Development Kits (SDKs) for seamless third-party integration. With a passion for cutting-edge technologies and a commitment to code quality, I am poised to drive impactful innovation in your team.
      </p>
    </div>
  );
}